/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Quality of Life"), "\n", React.createElement(_components.h3, null, "Reload Config after changes without closing terminal"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "source ~/.bashrc # or .zshrc\n\n\n")), "\n", React.createElement(_components.h3, null, "Custom Prompts"), "\n", React.createElement(_components.p, null, "In your ", React.createElement(_components.code, null, ".bashrc"), " or ", React.createElement(_components.code, null, ".zshrc"), " file you can add custom prompts to your terminal."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt:title=\".bashrc\""
  }, "PROMPT=\"%(?.%F{green}->.%F{red}->)%f %B%F%n@%m-%1~%f%b \"\n")), "\n", React.createElement(_components.p, null, "This results as a prompt that looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "-> user@hostname-<parentofcurrentdir>\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://github.com/ogham/exa"
  }, "exa")), "\n", React.createElement(_components.p, null, "A modern replacement for ", React.createElement(_components.code, null, "ls"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "exa -la\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://github.com/sharkdp/bat"
  }, "bat")), "\n", React.createElement(_components.p, null, "A cat(1) clone with syntax highlighting and Git integration."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "bat file.txt\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
